<template>
  <div class="main-content">
    <div class="sub-title">LATEST WORKS</div>
    <hr />
    <!-- <div class="video-categories">
      <div
        class="video-category"
        :class="isSelected('') ? 'video-category-selected' : ''"
        @click="selectCategory('')"
      >
        All
      </div>
      <div
        class="video-category"
        :class="isSelected('movie') ? 'video-category-selected' : ''"
        @click="selectCategory('movie')"
      >
        Movies
      </div>
      <div
        class="video-category"
        :class="isSelected('game') ? 'video-category-selected' : ''"
        @click="selectCategory('game')"
      >
        Games
      </div>
    </div> -->
    <div
      class="video-container"
      v-for="content in currentContentData"
      :key="content.videoId"
    >
      <VideoBlock :content="content" />
    </div>
  </div>
</template>

<script>
import VideoBlock from "../components/VideoBlock.vue";
export default {
  data() {
    return {
      contentData: [
        {
          title: "Next Flight Home",
          description:
            "<strong class='first'>First Prize of composition</strong> for the <a class='context' href='https://indiefilmmusiccontest.com/previous-competitions/' target='_blank'>Indie Film Music Contest</a> 2021. <br/><br/>\
          Next Flight Home is a short film that follows a pigeon as he makes the biggest decision of his life. \
          He has everything a pigeon could ever want. But when he meets a beautiful \
          dove one day on the street, he realizes how much better and more exciting his life is with her.<br><br>\
          Directed by Jake Wegesin at <a href='https://ambient.press/space-race' target='_blank'>Ambient Press</a>.",
          videoId: "https://www.youtube.com/embed/gw0UzBCl-F0?start=5",
          type: "Short movie",
          style: "Orchestral x Big Band Jazz",
          year: "2021",
          tag: "movie",
        },
        {
          title: "Murs Porteurs",
          description:
            "<strong class='first'>People's Choice Award</strong> and <strong class='first'>2nd Jury Prize</strong> for\
             the <a class='context' href='https://www.ptitclap.com/' target='_blank'>Ptit Clap Festival</a> 2023. <br/><br/>\
            <i>Supporting Walls</i> features a young saxophonist who trains hard for an audition that could change his life. But the walls are thin\
             in his small Parisian studio, which will quickly annoy his neighbors. <br/>Between arguments and support, \
             discover the evolution of these sometimes major, sometimes minor issues through these load-bearing walls.<br><br>\
             Directed by Jan Wallborn.",
          videoId: "https://www.youtube.com/embed/PZHNDJCDTIQ?start=3",
          type: "Short movie",
          style: "Jazz",
          year: "2022",
          tag: "movie",
        },
        {
          title: "The Ultimate Space Race",
          description:
            "Composition for a comedic animated movie recounting the events of the space race that took place between the USA and \
            the USSR from 1955 until 1969.<br/> <br/>\
            Directed by Jake Wegesin at <a href='https://ambient.press/space-race' target='_blank'>Ambient Press</a>.<br/>\
            Featured on <a href='https://www.stashmedia.tv/jake-wegesin-launches-new-studio-ambient-press-with-the-ultimate-space-race-short-film/' target='_blank'>Stash Media</a>, \
            <a href='https://goodmoves.tv/day/2022-05-19' target='_blank'>Goodmoves.tv</a>, \
            <a href='https://motionographer.com/quickie/ambient-press-the-ultimate-space-race/' target='_blank'>Motionographer</a>,\
            <a href='https://theawesomer.com/the-ultimate-space-race/671455/' target='_blank'>The Awesomer</a>.",
          videoId: "https://player.vimeo.com/video/710240034?h=f2b0756172",
          type: "Short movie",
          style: "Big Band Jazz",
          year: "2022",
          tag: "movie",
        },
        {
          title: "Sprite Fright",
          description:
            "Composition for the Score Relief's animated movie 2022. <br/> <br/>\
          When a group of rowdy teenagers trek into an isolated forest, they discover peaceful mushroom creatures that turn out to be an unexpected force of nature.",
          videoId: "https://www.youtube.com/embed/SfQpwTQeUHE?start=4",
          type: "Short movie",
          style: "Orchestral",
          year: "2021",
          tag: "movie",
        },
        {
          title: "Maija",
          description:
            "Composition for the OST Challenge's animated movie 2022. <br/> <br/>\
          In a lost village in the Nordic islands, a little girl is waiting for her father, gone fishing at sea.",
          videoId:
            "https://fast.wistia.net/embed/iframe/yudrz3sbid?videoFoam=true",
          type: "Short Movie",
          style: "Modern Orchestral",
          year: "2022",
          tag: "movie",
        },
        {
          title: "Klaus",
          description:
            "Composition for the ScoreLive London's animated movie 2022. <br/> <br/>\
          Klaus and his mailman companion desperately try to escape from mean villagers who want to destroy all the Christmas gifts,\
          while solving their personal issues.",
          videoId: "https://www.youtube.com/embed/CunSChEN4PI?start=5",
          type: "Movie (extract)",
          style: "Orchestral",
          year: "2022",
          tag: "movie",
        },
        {
          title: "I Catch You",
          description:
            "Composition for the Score Relief's short movie 2022. <br/> <br/>\
          When enough is enough you shine a spotlight. Becoming brave not bitter to RISE UP.<br/>\
          Poem written by Colette Brown, with Four Of Wands Productions.",
          videoId: "https://www.youtube.com/embed/33ytmDeZVx8?start=3",
          type: "Short movie",
          style: "Orchestral",
          year: "2022",
          tag: "movie",
        },
        {
          title: "Lie.e.s",
          description:
            "Cinzia Mura and Eléa Pouget host a bi-weekly podcast, engaging guests in deep explorations of love, friendships, family, \
            and societal norms, all while tackling feminist issues. <br/><br/>\
            Listen to the podcast on <a href='https://smartlink.ausha.co/liees' target='_blank'>all platforms</a>.",
          videoId:
            "https://open.spotify.com/embed/episode/6B6K78vMugCastf3Bhotqt?utm_source=generator",
          type: "Podcast",
          style: "Electronic",
          year: "2023",
          tag: "movies",
        },
        {
          title: "100 keys to your heart",
          description:
            "You play as a key, and must pick up others keys to open the doors in your way. \
            The more keys you pick up, the heavier you are — some even have special powers! Which key to pick up and when ?<br/><br/>\
            Created and produced by YorshInGame. Play it on <a href='https://store.steampowered.com/app/2237350/100_Keys_To_Your_Heart/' target='_blank'>Steam</a>.",
          videoId: "https://www.youtube.com/embed/4B8_G9X1eG4",
          type: "Video Game",
          style: "Electronic",
          year: "2022",
          tag: "game",
        },
        {
          title: "Genesis",
          description:
            "Composition for the Berlin International Film Scoring Contest 2022.<br/><br/> Our protagonist lands on a foreign \
            planet finds an alien artifact and travels back into a time, where the planet was covered by a lush forest.",
          videoId: "https://www.youtube.com/embed/U_NlW3farCo?start=18",
          type: "Short movie",
          style: "Orchestral",
          year: "2021",
          tag: "movie",
        },
        // {
        //   title: "RAN",
        //   description:
        //     "Composition for the Nikon Film Festival 2022. <br/> <br/>\
        //     Always suffering from Kelian's toxic friendship, Hugo will face — in spite of himself — strangeness to find Anna, the girl of his dream. <br/>\
        //     Directed by Ibrahim Bersanov.",
        //   videoId: "https://www.festivalnikon.fr/embed/2021/922",
        //   type: "Short movie",
        //   style: "Orchestral",
        //   year: "2021",
        // },
        // {
        //   title: "Et après toi ?",
        //   description:
        //     "Composition for the Nikon Film Festival 2022. <br/> <br/>\
        //     Louise's dream? To spend her life alongside the love of her life, Marcel. <br/>\
        //     Directed by Ysialine Brocard.",
        //   videoId: "https://www.festivalnikon.fr/embed/2021/1346",
        //   type: "Short movie",
        //   style: "Orchestral",
        //   year: "2021",
        // },
        {
          title: "Running Shapes",
          description:
            "Running Shapes is a mobile game where the player shapeshifts constantly and must go through \
          the correct walls to fit them perfectly. As the level goes by, everything goes faster, but you gain access to special bonuses \
          based on your current shape.<br/><br/>\
          Download it on the \
          <a href='https://apps.apple.com/us/app/running-shapes/id1515596961' target='_blank'>App Store</a> and the \
          <a href='https://play.google.com/store/apps/details?id=com.benjaminWidawski.runningShapes&hl=en_US&gl=US' target='_blank'>Play Store</a>",
          videoId: "https://www.youtube.com/embed/cVXAKeXYFg8",
          type: "Video Game",
          style: "Big Band",
          year: "2018",
          tag: "game",
        },
        {
          title: "Tarot Des Mages",
          description:
            "Tarot Des Mages is a computer strategy game. Each player has a deck of cards and tries to win by placing \
          them carefully on the board. If the colliding side of the card is lower, both tiles are won, until it is taken back again.",
          videoId: "https://www.youtube.com/embed/85G4jMx_HZo",
          type: "Video Game",
          style: "Orchestral",
          year: "2021",
          tag: "game",
        },
        {
          title: "Shake Island",
          description:
            "Shake Island is a computer memory-training game that takes place on an island where earthquakes \
          are more frequent than ever. \
          <br/>The player goes from house to house and tries to help the citizen by remembering where the furnitures \
          were placed before the big shaking! Afterwords, he must place everything as it was before.",
          videoId: "https://www.youtube.com/embed/0zBDdGn_pJc",
          type: "Video Game",
          style: "Big Band",
          year: "2019",
          tag: "game",
        },
        {
          title: "Flying Hoops",
          description:
            "Flying Hoops is a mobile game where the player embodies a butterfly trying to reach the end of \
          the level by flying through floating hoops to get the highest score. The music is bucolic and it immerses the player \
          in this beautiful yet somehow dangerous universe.",
          videoId: "https://www.youtube.com/embed/bh5sV_gOCV8",
          type: "Video Game",
          style: "Orchestral",
          year: "2019",
          tag: "game",
        },
      ],
      category: "",
    };
  },
  computed: {
    currentContentData() {
      if (this.category.length === 0) return this.contentData;
      return this.contentData.filter(
        (c) => c.tag.localeCompare(this.category) === 0
      );
    },
  },
  methods: {
    isSelected(category) {
      return this.category === category;
    },
    selectCategory(category) {
      this.category = category;
    },
  },
  components: {
    VideoBlock,
  },
};
</script>

<style scoped>
hr {
  margin-bottom: 0;
}

.video-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sub-title {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 2.5em;
}

.video-categories {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding-top: 0.8em;
}

.video-category {
  margin: 0 1em 0.5em;
  /* margin-bottom: 2rem; */
  /* background-color: #eeeeee; */
  border: 2px solid #eeeeee;
  /* border: 1px solid rgb(172, 172, 172); */
  color: #8d8d8d;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 1rem;
  /* font-size: .8rem; */
  min-width: 2rem;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
}

.video-category:hover {
  cursor: pointer;
  /* background-color: #dcdcdc; */
  /* border-bottom: 1px solid rgb(204, 204, 204); */
  /* scale: 105%; */
  border: 2px solid #d2d1d1;
}

.video-category-selected {
  /* background-color: #bdd1fa; */
  background-color: white;
  color: rgb(46 117 240);
  border: solid 1px rgb(46 117 240 / 40%);
  box-shadow: rgb(46 117 240 / 40%) 2px 2px, rgb(46 124 240 / 20%) 5px 5px;
}
.video-category-selected:hover {
  cursor: pointer;
  background-color: white;
  border: solid 1px rgb(46 117 240 / 60%);
  box-shadow: rgb(46 117 240 / 40%) 2px 2px, rgb(46 124 240 / 20%) 5px 5px;
  /* background-color: #b1c6ef; */
}
</style>

<style>
.context {
  color: #4685f2 !important;
  font-weight: 800;
  text-decoration: none;
}

.context:hover {
  text-decoration: underline;
}
</style>