<template>
  <div class="block">
    <div class="col-60">
      <div class="embed-container">
        <iframe
          :src="content.videoId"
          frameborder="0"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          scrolling="no"
        ></iframe>
      </div>
    </div>
    <div class="col-40 alt">
      <h2>{{content.title}}</h2>
      <div class="text-breakline"></div>
      <p v-html="content.description"></p>
      <ul class="simple-list">
        <li>
          Media: <strong>{{content.type}}</strong>
        </li>
        <li>
          Genre: <strong>{{content.style}}</strong>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
}
</script>

<style>
.first {
  color: #ffbc00
}
</style>