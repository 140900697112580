<template>
  <footer id="footer">
    <p class="nav-item">&copy; Benjamin Widawski {{ currentYear }}</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
#footer {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding-right: 5em;
  margin-bottom: 3em;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  #footer {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-right: 0;
  }
}
</style>
